import React, { useState, useEffect } from "react"

const bannerStatus = { hidden: "hidden", visible: "visible" }

const Banner = () => {
  const [status, setStatus] = useState(bannerStatus.hidden)

  useEffect(() => {
    if (!localStorage.getItem("msp_cookie_policy")) {
      setStatus(bannerStatus.visible)
    }
  }, [])

  const onAgree = event => {
    event.preventDefault()
    localStorage.setItem("msp_cookie_policy", true)
    setStatus(bannerStatus.hidden)
  }

  return (
    status === bannerStatus.visible && (
      <div className="EUBanner">
        <div className="container">
          <p className="TextBody uTextWhite uNoMargin">
            This site uses cookies to offer you a better browsing
            experience&nbsp;
            <a
              className="Link white"
              href="https://mothership.cx/documents/privacy_policy.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Find out more
            </a>
          </p>
          <div className="actions">
            <a className="Btn" href="#banner" onClick={onAgree}>
              OK
            </a>
          </div>
        </div>
      </div>
    )
  )
}

export default Banner
