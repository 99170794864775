/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { withPrefix } from "gatsby"
import Header from "./header"
import Banner from "./banner"
import logoAndTextWhiteUrl from "../images/logo-and-text-white.svg"
import logoWhiteUrl from "../images/logo-white.svg"
import "../fonts/fonts.css"
import "./layout.css"

const Layout = ({ headerActions, children }) => {
  return (
    <>
      <Helmet>
        <script src={withPrefix("intercom.js")} type="text/javascript" />
        <script src="https://cdn.veriff.me/sdk/js/1.1/veriff.min.js"></script>
        <script src="https://cdn.veriff.me/incontext/js/v1/veriff.js"></script>
      </Helmet>
      <Banner />
      <Header headerActions={headerActions} />
      <main>{children}</main>
      <footer className="Footer">
        <div className="content">
          <picture>
            <source media="(min-width: 768px)" srcSet={logoAndTextWhiteUrl} />
            <img src={logoWhiteUrl} alt="Mothership M-shaped logo" />
          </picture>
          <div className="links">
            <a
              className="item Link"
              href="/documents/privacy_policy_2021_02_07.pdf"
            >
              Privacy Policy
            </a>
          </div>
          <div className="copyright uHidePhone">
            <span>
              Copyright © {new Date().getFullYear()} Mothership All rights
              reserved.
            </span>
          </div>
        </div>
        <hr className="linecut uShowPhoneOnly" />
        <div className="copyright uShowPhoneOnly">
          <span>
            Copyright © {new Date().getFullYear()} Mothership All rights
            reserved.
          </span>
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  headerActions: PropTypes.node,
}

Layout.defaultProps = {
  headerActions: null,
}

export default Layout
