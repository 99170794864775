import { Link } from "gatsby"
import React from "react"
import PropTypes from "prop-types"
import { MdExitToApp } from "react-icons/md"
import { statuses as authStatuses } from "../hooks/auth"
import { useAppState } from "../components/provider"
import logoUrl from "../images/logo.svg"
import logoWithTextUrl from "../images/logo-and-text.svg"

const Header = ({ headerActions }) => {
  const {
    auth: { status: authStatus, onSignOut },
  } = useAppState()

  return (
    <header className="Topbar">
      <div className="content">
        <div className="main">
          <Link to="/">
            <picture>
              <source media="(min-width: 768px)" srcSet={logoWithTextUrl} />
              <img
                className="logo"
                src={logoUrl}
                alt="Mothership M-shaped logo"
              />
            </picture>
          </Link>
        </div>
        <div />
        <div className="actions">
          {headerActions}
          {authStatus === authStatuses.loggedIn && (
            <Link to="/" onClick={onSignOut} className="Logout">
              <MdExitToApp size="2em" className="Icon plain" />
              Logout
            </Link>
          )}
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  headerActions: PropTypes.node,
}

Header.defaultProps = {
  headerActions: null,
}

export default Header
